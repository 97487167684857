
import { mapGetters, mapActions } from 'vuex'
import { formatData } from '../../constants/config.js'
export default {
	props: ["layout"],
	components: {
		vcLoading		: () => import('../../components/pages/loading.vue' ),
		vcFormatPrice	: () => import('../../components/product/format-price.vue' ),
		searchMobile	: () => import('../../components/header/search-mobile.vue')
	},
	data() {
		return {
			back_url		: process.env.VUE_APP_HEFESTO_API_URL_FILE,
			isLoading		: false,
			showMobileSearch: false,
			openSearch		: false,
			cartProducts	: null,
			cartTotal		: 0,
			countItems		: 0
		}
	},
	mounted() {

		this.$root.$on("show-mobile-search", this.chargeMobileSearch);
	},
	computed: {
		...mapGetters({
			getterLogin		: 'getterLogin',
			getterCart		: 'getterCart'
		}),
		getShowMobileSearch(){
			return this.showMobileSearch;
		}
	},
	methods: {
		...mapActions({
			actionCart: 'actionCart'
		}),
		formatCurrency(price, totalAmount = null){
			
			return formatData.currency(price, totalAmount);
		},
		chargeMobileSearch( value ){
			this.showMobileSearch = value;
		},
		openWidgetSearch(event){
			this.openSearch = true;
			this.$root.$emit('open-search-mobile', this.openSearch);
		},
		async removeCartItem(product) {
			this.isLoading = true;
			await this.actionCart({ action: 'remove', products: [{ product_id: product.product_id, variant_id: product.variant_id, quantity: parseInt(product.quantity) }] }).then( (result_cart) => {

				this.isLoading = false;
			});
		},
	}
}
