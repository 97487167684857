
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        vcLoading           : () => import("../../../components/pages/loading.vue"),
        breadcrumbs         : () => import("../../../components/pages/breadcrumbs.vue"),
        contentCollection   : () => import( '../../../components/product/collection-content.vue' )
    },
    data() {
        return {
            isLoading 			: true,
            access_catalog		: true,
            handleCollection	: null,
            current_date        : moment().format('YYYY-MM-DD'),
            disabled_button     : false
        };
    },
    async mounted(){
		this.$root.$on('change-access-catalog', this.changeAccessCatalog);
    },
    computed: {
        ...mapGetters({
            getterLogin                     : 'getterLogin',
            getterDownloadTransactionsFile  : "getterDownloadTransactionsFile",
            getterDownloadPriceCatalog      : "getterDownloadPriceCatalog"
        })
    },
    methods:{
        ...mapActions({
            downloadPriceCatalog: 'downloadPriceCatalog',
            deletePriceCatalog  : 'deletePriceCatalog'
        }),
        changeAccessCatalog(access) {
            this.access_catalog = access;
        },
        updateLoadingCollection(loading_collection){
            this.isLoading = loading_collection;
        },
        downloadExportFile( data_file ) {
            
            if( ![null, ""].includes( data_file ) ){
                
                document.getElementById("download_file").href = `${ process.env.VUE_APP_HEFESTO_API_URL_FILE }${ data_file }`;
                document.getElementById("download_file").download = `${ process.env.VUE_APP_HEFESTO_API_URL_FILE }${ data_file }`.substring( `${ process.env.VUE_APP_HEFESTO_API_URL_FILE }${ data_file }`.lastIndexOf('/') + 1 );
                document.getElementById("download_file").click();
                setTimeout(async () => {
                    
                    await this.deletePriceCatalog({ url_file: data_file });
                }, 3000);
            }
        },
        async createExportTable(){
            this.disabled_button = true;
            await this.downloadPriceCatalog().then( (result_file) => {
                
                this.disabled_button = false;
                this.downloadExportFile( this.getterDownloadPriceCatalog );
            });
        },
    }
}
