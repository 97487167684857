
let defaultBusinessData = {
    have_experience		: true,
    physical_store 		: true,
    target_sell 		: null,
    company_webside		: null,
    have_company_webside: true,
    social_networks 	: [
    { type: "facebook"	, user: "", value: 0 },
    { type: "instagram"	, user: "", value: 0 },
    { type: "tiktok"	, user: "", value: 0 }
    ],
    business_size		: null,
    terms_and_conditions: null,
    language			: null,
    note				: null
};
import { mapGetters } from 'vuex';
export default {
    props: ["idForm"],
    components:{
        termsAndConditions	: () => import("../../components/pages/terms-and-conditions.vue")
    },
    data() {
        return {
            businessData            : {...defaultBusinessData},
            options_languages		: [],
            options_targets			: [
            { label: "Physical"	, value: "Physical" },
            { label: "Virtual"	, value: "Virtual" },
            { label: "Both"		, value: "Both" }
            ],
            options_social_networks	: [
            { type: "facebook"	, icon: "fab fa-facebook-f"	, active: false, user: "", value: 0 },
            { type: "instagram"	, icon: "fab fa-instagram"	, active: false, user: "", value: 0 },
            { type: "tiktok"	, icon: "fab fa-tiktok"		, active: false, user: "", value: 0 }
            ],
            options_business_size	: [
            { label: "Only me"				, value: "1" },
            { label: "From 2 to 5 people"	, value: "2-5" },
            { label: "From 5 to 10 people"	, value: "5-10" },
            { label: "More than 10 people"	, value: "10" }
            ],
            
            defaultInvest		: null,
            isDefaultInvest		: true,
            actionForm          : null
        };
    },
    async mounted() {
        
        this.options_languages = this.getterLanguages.map((item) => {
            return { label: item.name, value: item._id };
        });
        this.businessData.language = this.options_languages.find( (item) => item.label == "English" );
        this.$root.$on('change-business-data', this.updateLeadData);
        this.$root.$on('valid-business-data', this.executeValidLeadData);
        this.$root.$on('reset-business-data', this.resetLeadData);
    },
    computed: {
        ...mapGetters({
            getterLanguages: 'getterLanguages'
        })
    },
    methods: {
        async updateLeadData(data_form){
            
            this.businessData = data_form;
            
            this.options_social_networks = this.businessData.social_networks;
        },
        executeValidLeadData(data_form){
            
            if( data_form.id_form == this.idForm ){
                
                this.actionForm = data_form.action;
                document.getElementById(`btn-${ data_form.id_form }`).click();
            }
        },
        resetLeadData(data_form){
            
            if( data_form.clean ){
                
                this.businessData = {...defaultBusinessData};
            }
            document.getElementById(`reset-form-${ data_form.id_form }`).click();
        },
        preSubmit(is_valid){
            
            if( this.actionForm ){
                
                this.businessData.social_networks = this.options_social_networks;
                this.$root.$emit('valid-form-business', { id_form: this.idForm, lead: this.businessData, valid: is_valid, action: this.actionForm });
            }
            this.actionForm = null;
        },
        showModalTermsAndConditions(){
            
            if( this.businessData.terms_and_conditions ){
                
                this.$refs['modal_terms_and_conditions'].show();
            }
            this.businessData.terms_and_conditions = null;
        },
        agreeTermsAndConditions(refName){
            
            this.businessData.terms_and_conditions = true;
            this.$refs[refName].hide();
        },
        hideModal(refName){
            
            this.$refs[refName].hide();
        }
    }
};
