
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
        vcLoading: () => import('../../components/pages/loading.vue')
    },
    data() {
        return {
            backCmsUrl          : process.env.VUE_APP_CMS_BACK_URL_FILE,
            blogsByCategory     : [],
            show_mobile_carousel: false,
            isLoading           : true
        }
    },
    async mounted(){
        window.addEventListener('load', this.resizePage);
        window.addEventListener('resize', this.resizePage);
        await this.getListCategoryArticless().then( (result_articless) => {
            
            this.isLoading = false;
        });
    },
    computed:{
        ...mapGetters({
            getterListCategoryArticles: 'getterListCategoryArticles'
        })
    },
    methods:{
        ...mapActions({
            getListCategoryArticless: 'getListCategoryArticless'
        }),
        resizePage(event){
            this.show_mobile_carousel = window.innerWidth <= 991;
        },
        formatDate( date ){
            return moment(date).format('MMMM DD, YYYY');
        }
    }
}
