
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        vcLoading   : () => import("../../../components/pages/loading.vue"),
        breadcrumbs : () => import("../../../components/pages/breadcrumbs.vue")
    },
    data() {
        return {
            isLoading       : true,
            agent           : null,
            profile_image   : '',
            hide_profile    : true
        };
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
            getterCustomerAgent: 'getterCustomerAgent'
        }),
    },
    methods:{
        ...mapActions({
            getCustomerAgent: "getCustomerAgent"
        })
    },
    async mounted(){
        await this.getCustomerAgent().then( (result_agent) => {
            
            this.agent = this.getterCustomerAgent;
            if( this.agent == null || this.agent.photo == '' ){
                
                this.profile_image = `${ process.env.VUE_APP_HEFESTO_API_URL_FILE }/images/svg-store/svg-profile-image/svg-secondary.svg`;
            }
            else{
                
                this.profile_image = this.agent.photo;
            }
            this.hide_profile = false;
            this.agent.phone = this.agent.phone ? this.agent.phone : '';
            this.agent.whatsappLink = this.agent.phone != '' ? `https://api.whatsapp.com/send?phone=${ this.agent.phone.replace(/ +/g, '').replace('+', '') }` : '';
            this.isLoading = null;
        });
    }
}
