
export default {
    components: {
        vcLoading   : () => import("../../components/pages/loading.vue"),
    },
    data() {
        return {
            isLoading: true,
        }
    },
    mounted(){
        
		window.location.href = 'https://pal-negocio.myshopify.com/admin';
    }
}
