
import { mapGetters } from 'vuex';
export default {
    props: ["position", "getterLevel"],
    data() {
        return {
            file_url	: process.env.VUE_APP_HEFESTO_API_URL_FILE,
            avatar		: null,
            showProfile	: false,
        }
    },
    async mounted() {
        
        this.updateProfileImage();
        this.$root.$on('update-profile-image', this.getLoginProfileImage);
    },
    computed: {
        ...mapGetters({
            getterUser              : "getterUser",
            getterProfileImage		: 'getterProfileImage'
        }),
        getProfileImage(){
            
            this.updateProfileImage();
            return this.avatar;
        },
        isShowProfile(){
            return this.showProfile;
        }
    },
    methods: {
        hideProfileImage(){
            
            this.showProfile = false;
        },
        updateProfileImage(){

            let cookie_user = this.$cookies.get('user-store');
            this.avatar = !this.getterProfileImage ? `${ this.file_url }${ this.getterUser.profile_image }` : this.getterProfileImage;
            this.showProfile = true;
            cookie_user.profile_image = this.avatar;

            this.$cookies.set('user-store', JSON.stringify(cookie_user), { path: '/', maxAge: 60 * 60 * 24 * 7 });
        },
        getLoginProfileImage(data_image){
            this.avatar = `${ this.file_url }${ data_image }`;
            this.showProfile = true;
        }
    }
}
