
import { mapGetters, mapActions } from "vuex";
export default {
    layout: 'only-content',
    components: {
        vcLoading		: () => import('../../components/pages/loading.vue' ),
    },
    data() {
        return {
            title_page: 'Cata1og.com | Sales Executive',
            isLoading: true,
            SMSPhone: null,
            mainLocation: null
        }
    },
    async mounted() {
        
        setTimeout(async () => {
            await this.getAgent({ handle: this.$route.params.handle }).then( (result) =>{

                this.isLoading = false;
                for (const item_phone of ( this.getterCompanyData?.contact_information.phones || [])) {
                    if( item_phone.sms ){

                        this.SMSPhone = item_phone;
                    }
                }
                for (const item_location of ( this.getterCompanyData?.contact_information.locations || [])) {
                    if( item_location.main ){

                        this.mainLocation = item_location;
                    }
                }
                this.title_page = `Cata1og.com | Sales Executive - ${ this.getterAgent?.name }`;
            });
        }, 500);
    },
    computed:{
        ...mapGetters({
            getterCompanyData: 'getterCompanyData',
            getterAgent: 'getterAgent'
        }),
        getSMSPhone(){
            return this.SMSPhone;
        },
        getMainLocation(){
            return this.mainLocation;
        },
        getLoading(){
            return this.isLoading;
        },
        getTitlePage(){
            return this.title_page;
        }
    },
    methods: {
        ...mapActions({
            getAgent: 'getAgent'
        }),
    },
	head() {
		return {
			title: this.getTitlePage
		}
	}
}
