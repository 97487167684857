
import { mapGetters, mapActions } from "vuex";
export default {
    props: ['email'],
    data() {
        return { 
            showPassword: false,
            showConfirmPassword: false,
            data_password: {
                password: null,
                passwordConfirmation: null
            }
        };
    },
    async mounted(){
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin'
        })
    },
    methods: {
        ...mapActions({
            updateCustomerPassword: 'updateCustomerPassword'
        }),
        updatePassword(){
            
            this.$root.$emit('change-loading', true);
            this.updateCustomerPassword({ email: this.email, password: this.data_password.password }).then( (result_password) => {
                    
                this.data_password.password = null;
                this.data_password.passwordConfirmation = null;
                if( this.getterLogin ){

                    document.getElementById('reset-form').click();
                }
                else{

                    this.$router.push('/account/login');
                }
                
                this.$root.$emit('change-loading', false);
            });
        }
    }
}
