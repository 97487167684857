
import moment from "moment";
import { mapGetters, mapActions } from 'vuex';
export default {
    async asyncData({ params }){

    },
    components: {
        breadcrumbs : () => import('../../components/pages/breadcrumbs.vue'),
        vcLoading   : () => import('../../components/pages/loading.vue')
    },
    data() {
        return {
            storeUrl: process.env.VUE_APP_STORE_URL,
            isLoading: true
        }
    },
    async mounted(){
        let handle = this.$route.params.handle;
        window.addEventListener('load', this.resizePage);
        window.addEventListener('resize', this.resizePage);
        
        await this.getItemBlog({ handle: handle }).then( (result) => {
            
            this.isLoading = false;
        });
    },
    computed:{
        ...mapGetters({
            getterItemArticle: 'getterItemArticle'
        }),
        getPublishedDate(){
            return moment(this.getterItemArticle?.published_at).format('MMMM DD, YYYY');
        },
        getShareUrl(){
            return encodeURI(`${ this.storeUrl }/blog/${ this.getterItemArticle?.handle }`);
        }
    },
    methods:{
        ...mapActions({
            getItemBlog: 'getItemBlog'
        }),
        resizePage(event){
            this.show_mobile_carousel = window.innerWidth <= 991;
        },
        formatDate( date ){
            return moment(date).format('MMMM DD, YYYY');
        }
    }
}
