import { render, staticRenderFns } from "./_handle.vue?vue&type=template&id=35e96024"
import script from "./_handle.vue?vue&type=script&lang=js"
export * from "./_handle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Product: require('/home/ubuntu/cata1og-storefront/components/product/product.vue').default})
