
export default {
	components:{
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
		registerPage: () => import('../../components/account/register-page.vue')
	},
	data() {
		return {
			banner: "banners/banner-form-leads-fajas_full.webp",
			title: "Looking for the best Fajas Colombianas wholesaler?",
			message: "SKYROCKET YOUR PROFITS WITH CATA1OG!",
			source: "main-register-page"
		}
	}
}
