
export default {
    props: ['type_menu', 'item_menu', 'index_menu'],
    data() {
        return {
            activeItem          : '',
            activeChildItem     : '',
            activeSubChildItem  : ''
        }
    },
    methods: {
        isActive(menuItem) {
            
            return this.activeItem === menuItem;
        },
        setActive(menuItem) {
            
            this.activeItem = this.activeItem === menuItem ? '' : menuItem;
        },
        isActiveChild(menuChildItem) {
            
            return this.activeChildItem === menuChildItem;
        },
        setActiveChild(menuChildItem) {
            
            this.activeChildItem = this.activeChildItem === menuChildItem ? '' : menuChildItem;
        },
        isActiveSubChild(subChildItem) {
            
            return this.activeSubChildItem === subChildItem;
        },
        setActiveSubChild(subChildItem) {
            
            this.activeSubChildItem = this.activeSubChildItem === subChildItem ? '' : subChildItem;
        },
		navItemOptionOpen(event, navigate, menuItem, type_menu, type_item){
			if( menuItem ){
                if( type_item == 'child-item' ){
                    this.setActiveChild(menuItem);
                }
                else if( type_item == 'sub-child-item' ){
                    this.setActiveSubChild(menuItem);
                }
			}
            if( type_menu == 'mobile'){
                this.$root.$emit('close-sidebar');
            }
			navigate(event);
		}
    }
}
