
import { mapGetters } from 'vuex';
export default {
	components: {
		topbarDefault	: () => import('../topbar/topbar-default.vue'),
		sidebar			: () => import('../header/sidebar.vue'),
		navBarDefault	: () => import('../navbar/navbar-default.vue'),
		widgets			: () => import('../header/widgets.vue')
	},
	data() {
		
		return {
			isLandingPage		: this.$route.path.indexOf('/landing/') >= 0,
			isBlogPage			: this.$route.path.indexOf('/blog/') >= 0,
			openSidebar			: false,
			fixed_header		: false,
			effect_header		: false,
			show_navbar			: false,
			store_information	: null
		}
	},
	async mounted(){
		
		window.addEventListener('load', this.evalShowNavbar);
		window.addEventListener('resize', this.evalShowNavbar);
		window.addEventListener('scroll', this.evalShowNavbar);
		this.$root.$on('close-sidebar', this.closeSidebar);
	},
	computed: {
		...mapGetters({
			getterCompanyData: 'getterCompanyData'
		}),
		showNavbar(){
			return this.show_navbar;
		}
	},
	methods: {
		evalShowNavbar( event ) {
			this.show_navbar = window.innerWidth <= 1200;
			// this.effect_header = ( window.innerWidth <= 991 ? 89 : 150 ) <= window.top.scrollY;
			// setTimeout(() => {
			// 	this.fixed_header = ( window.innerWidth <= 991 ? 89 : 150 ) <= window.top.scrollY;
			// }, 300);
		},
		executeOpenSidebar() {
			this.openSidebar = true
		},
		closeSidebar(isOpenSidebar) {
			this.openSidebar = isOpenSidebar
		}
	}
}
