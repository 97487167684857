import { render, staticRenderFns } from "./jeans-colombianos-wholesale-sign-up.vue?vue&type=template&id=92264438"
import script from "./jeans-colombianos-wholesale-sign-up.vue?vue&type=script&lang=js"
export * from "./jeans-colombianos-wholesale-sign-up.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports