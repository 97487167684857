
import { mapGetters, mapActions } from "vuex";
import { formatData } from "../../../constants/config.js"
export default {
    components: {
        vcLoading   : () => import("../../../components/pages/loading.vue"),
        breadcrumbs : () => import("../../../components/pages/breadcrumbs.vue"),
        profileImage: () => import("../../../components/account/profile-image.vue")
    },
    data() {
        return {
            back_url         : process.env.VUE_APP_HEFESTO_API_URL_FILE,
            isLoading       : true,
            percent_range   : 0,
            showResumeInfo  : true,
            showFormInfo    : false,
            customer        : null,
            
            listPhoneCodes  : [],
            phoneNumber     : "",
            countryPhoneCode: "US-1",
        };
    },
    directives: {
        'number-only': {
            bind (el) {
                el.addEventListener('keypress', (event) => {
                    event.target.value = event.target.value.replace(/[^0-9]/g, '')
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true
                    }
                    event.preventDefault()
                });
            }
        }
    },
    async mounted(){
        
        await this.getCountries().then( async (result_countries) => {
            
            this.listPhoneCodes = this.getterCountries.phone_codes;
            await this.getCustomer({ id_email: this.getterUser.email }).then( async (result_customer) => {
                
                this.customer   = {
                    email       : this.getterCustomer.email,
                    first_name  : this.getterCustomer.first_name,
                    last_name   : this.getterCustomer.last_name,
                    phone       : this.getterCustomer.phone
                };
                let data_phone  = this.validPhoneNumber(this.getterCustomer.phone);
                
                this.phoneNumber        = data_phone.number;
                this.countryPhoneCode   = data_phone.code;
                this.customer.phone     = data_phone.format;
                
                await this.getLevelCustomer().then( (result_level) => {
                    
                    let num_range = this.getterLevel != null ? ( this.getterLevel.total_fact * 100 ) / this.getterLevel.nextLevel.lowerLimit : 0;
                    
                    this.percent_range = num_range > 100 ? 100 : num_range;
                    this.$root.$emit('update-profile-image', this.getterProfileImage);
                    this.isLoading = null;
                });
            });
        });
    },
    computed: {
        ...mapGetters({
            getterLogin         : 'getterLogin',
            getterUser          : 'getterUser',
            getterLevel         : 'getterLevel',
            getterProfileImage  : 'getterProfileImage',
            getterCustomer      : 'getterCustomer',
            getterCountries     : 'getterCountries', 
        }),
        getDataListPhoneCodes(){
            return this.listPhoneCodes;
        },
        getCustomerInfo(){
            return this.customer;
        }
    },
    methods: {
        ...mapActions({
            updateCustomer          : 'updateCustomer', 
            updateCustomerAddress   : 'updateCustomerAddress',
            getCountries            : 'getCountries',
            getCustomer             : 'getCustomer',
            getLevelCustomer        : 'getLevelCustomer',
            changeProfileImage      : 'changeProfileImage',
            logoutUser              : 'logoutUser',
        }),
        editInfo() {
            this.showResumeInfo = false;
            this.showFormInfo = true;
        },
        hideInfo() {
            this.showResumeInfo = true;
            this.showFormInfo = false;
        },
        async saveChangeInfo() {
            let body_request = {
                old_email   : this.getterCustomer.email,
                email       : this.customer.email,
                agent_email : this.getterCustomer.agent_email,
                first_name  : this.customer.first_name,
                last_name   : this.customer.last_name,
                phone       : this.customer.phone,
                note        : this.customer.note
            };
            await this.updateCustomer(body_request).then( (result_customer) => {
                
                let data_phone          = this.validPhoneNumber(this.customer.phone);
                
                this.phoneNumber        = data_phone.number;
                this.countryPhoneCode   = data_phone.code;
                this.customer.phone     = data_phone.format;
                this.hideInfo();
            });
        },
        searchImage(){
            
            document.getElementById("profile_image").click();
        },
        async chargeImage(){
            let body_request = { 
                file: document.getElementById("profile_image").files[0],
                input_name: "profile_image",
                max_size: 512000,
                old_path_file: this.getterUser ? this.getterUser.profile_image.replace(`${ this.back_url }/`, '') : "", 
                extra_data: {
                    category_file: "image",
                    body:[
                    { name: "base_path", value: "/images/profiles" },
                    { name: "max_dimension", value: JSON.stringify( { "width": 2000, "height": 2000 } ) },
                    { name: "default_file_name", value: "" }
                    ]
                }
            };
            await this.changeProfileImage(body_request);
            this.$refs["profile_image"].reset();
        },
        validPhone(input_value){
            
            this.customer.phone      = `+${ this.countryPhoneCode.split("-")[1] }${ this.phoneNumber }`;
        },
        validPhoneNumber(phone){
            
            if( phone ){
                
                phone = phone.replace(/([ +])+/g, "");
                
                let code = phone.replace(phone.slice(-10), "");
                
                let index_country = code == "" ? -1 : this.listPhoneCodes.findIndex( (item) => item.phone_code == parseInt(code) );
                
                let country_code = ( code != "" && code == "1" ) || index_country < 0 ? "US-1" : this.listPhoneCodes[index_country].id;
                
                return {
                    code    : country_code,
                    number  : phone.slice(-10),
                    format  : `+${ country_code.split("-")[1] }${ phone.slice(-10) }`
                };
            }
            else{
                
                return {
                    code    : "US-1",
                    number  : "",
                    format  : ""
                };
            }
        },
        formatCurrency( amount_value ){
            
            return formatData.currency( amount_value );
        },
        logout() {
            this.logoutUser({});
            this.$router.push('/account/login');
        },
    }
}
