
import { mapGetters, mapActions } from 'vuex';
import { effectSlider }  from '../constants/config.js';
export default {
	components: {
		slider			    : () => import( '../components/home/slider.vue' ),
		productCollection	: () => import('../components/product/product-collection.vue'),
		logoSlider			: () => import( '../components/home/logo-slider.vue' ),
		faq					: () => import( '../components/account/faq.vue' )
	},
	data() {
		return {
			isLoading					: true,
			collection_products			: [],
		}
	},
	async mounted() {
		window.addEventListener('load', this.loadPage);
		
		await this.getCollectionHome();
	},
	computed: {
		...mapGetters({
			getterCollectionHome: 'getterCollectionHome',
		}),
	},
	methods: {
		...mapActions({
			getCollectionHome: 'getCollectionHome'
		}),
		loadPage(event){
			setInterval(() => {
				if( this.getterCollectionHome ){
					
					effectSlider('slider-top-products', this.getterCollectionHome.length, 1500 );
				}
				effectSlider('slider-services', 4, 1500 );
				effectSlider('slider-benefits', 4, 1500 );
				effectSlider('slider-first-order', 2, 1500 );
			}, 3000);
		}
	}
}
