
import { mapGetters, mapActions } from 'vuex';
export default {
	components: {
		topbarDefault	: () => import('../topbar/topbar-default.vue'),
		profileImage	: () => import('../account/profile-image.vue')
	},
	data() {
		
		return {
			isLandingPage		: this.$route.path.indexOf('/landing/') >= 0,
			isBlogPage			: this.$route.path.indexOf('/blog/') >= 0,
			openSidebar			: false,
			fixed_header		: false,
			effect_header		: false,
			show_navbar			: false,
			store_information	: null
		}
	},
	async mounted(){
		
		await this.loginUserEvent();
		this.$root.$on('login-user', this.loginUserEvent);
	},
	computed: {
		...mapGetters({
			getterLogin				: 'getterLogin',
			getterCompanyData		: 'getterCompanyData',
			getterUser				: 'getterUser',
			getterLevel				: 'getterLevel'
		})
	},
	methods: {
		...mapActions({
			getLevelCustomer: 'getLevelCustomer',
			logoutUser		: 'logoutUser',
		}),
		async loginUserEvent(isLogin){
			
			if( this.getterLogin ){
				
				await this.getLevelCustomer();
			}
		},
		openAccount($event){
			
			this.$router.replace("/account/dashboard");
		},
		logout() {
			this.logoutUser();
			this.$router.push('/account/login');
		}
	}
}
