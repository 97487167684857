
import { mapGetters, mapActions } from 'vuex'
export default {
	components: {
		breadcrumbs: () => import( '../../components/pages/breadcrumbs.vue' )
	},
	data() {
		return {
			email: "",
			password: "",
			showPassword: false,
			availableLogin: false
		}
	},
	computed: {
		...mapGetters({
			getterLogin			: 'getterLogin',
			getterUser			: 'getterUser',
			getterProfileImage	: 'getterProfileImage',
			getterNotification	: 'getterNotification'
		}),
		isAvailableLogin(){
			return this.availableLogin;
		}
	},
	mounted(){
		if ( this.getterLogin ) {
			
			this.$router.push("/");
		}
	},
	methods: {
		...mapActions({
			loginUser: "loginUser"
		}),
		async onSubmit() {
			if(this.email.trim() != "" && this.password.trim() != "") {
				this.availableLogin = true;
				await this.loginUser({ email: this.email, password: this.password }).then( (result_user) => {
					
					this.availableLogin = false;
					if(this.getterUser){
						
						this.$root.$emit('update-profile-image', this.getterProfileImage);
						this.$root.$emit('login-user', true);
						this.$root.$emit("show-desktop-search", false);
						this.$root.$emit("show-desktop-search", true);
						this.$router.replace('/');
					}
				});
			}
		},
	}
}
