
import { mapGetters } from "vuex";
export default {
	components: {
		vcLoading						: () => import("../../components/pages/loading.vue"),
		breadcrumbs						: () => import("../../components/pages/breadcrumbs.vue"),
		collectionSectionPrivateLabel	: () => import("../../components/product/collection-section-private-label.vue"),
		contentCollection   			: () => import( '../../components/product/collection-content.vue' ),
		countDown   					: () => import( '../../components/pages/count-down.vue' )
	},
	data() {
		return {
			isLoading 			: true,
			access_catalog		: false,
			handleCollection	: null,
			search_value 		: null
		};
	},
	async mounted() {
		
		if( this.$route.params.handle == 'search' ){
			this.$route.query.result = this.$cookies.get('value-search');
			this.search_value = this.$cookies.get('value-search');
		}
		this.handleCollection 		= !this.$route.params.handle ? "all-products" : this.$route.params.handle;

		this.$root.$on('loading-content-collection', this.updateLoadingCollection);
		this.$root.$on('change-access-catalog', this.changeAccessCatalog);
		this.$root.$on('end-count-down', this.endCountDown);
	},
	computed: {
		...mapGetters({
			getterLogin				: 'getterLogin',
			getterCollection		: "getterCollection"
		}),
		getSearchValue(){
			return this.search_value;
		},
		getLoadingPage(){
			return this.isLoading;
		},
		getAccessCatalog(){
			return this.access_catalog;
		},
		getHandleCollection(){
			return this.handleCollection;
		},
	},
	methods: {
		changeAccessCatalog(access) {
			this.access_catalog = access;
		},
		updateLoadingCollection(loading_collection){
			this.isLoading = loading_collection;
		},
		endCountDown(){

			if( this.getAccessCatalog ){
				
				window.location.reload();
			}
		}
	}
};
