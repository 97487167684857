
import { mapGetters } from 'vuex';
export default {
	props: ["store_info"],
	data() {
		return {
			store_information: this.store_info,
			store_path: this.$route.path
		}
	},
	async mounted(){
		this.$root.$on('update-store-information', this.updateStoreInformation);
	},
	computed: {
		...mapGetters({
			getterCompanyData: 'getterCompanyData'
		}),
		getStorePath(){
			return this.store_path;
		}
	},
	methods: {
		updateStoreInformation(storeInfoData){
			
			this.store_information = storeInfoData;
		}
	}
}
