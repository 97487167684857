
import { mapGetters } from 'vuex';
import { formatData, calcDiscountPrice } from "../../constants/config.js";
export default {
	props: ['type', 'discount_brand', 'discount_percentage', 'price', 'discount_price', 'quantity', 'discount_stock', 'apply_discount_stock'],
	name: 'vc-format-price',
	computed:{
		...mapGetters({
			getterLogin: 'getterLogin'
		}),
		formatPrice(){
			
			let prev_price = ['collection', 'price-catalog'].includes(this.type) ? this.price.min_price : this.price;
			
			return formatData.currency( prev_price * ( this.type == 'cart-total' ? this.quantity : 1 ) ).format;
		},
		formatDiscountPrice(){
			
			let prev_price = ['collection', 'price-catalog'].includes(this.type) ? this.price.min_price : this.price;
			let prev_quantity = ['cart', 'cart-header', 'cart-total'].includes(this.type) ? this.quantity : 1;
			let include_quantity = this.type == 'cart-total' ? prev_quantity : 1;
			let new_discount = this.discount_brand;
			if( this.apply_discount_stock && this.discount_stock?.value && this.discount_stock?.value > 0 ){

				new_discount = ( new_discount + ( ( this.discount_stock.value * ( 100 - new_discount ) ) / 100 ) );
			}
			if( this.discount_percentage > 0 ){

				new_discount = ( new_discount + ( ( this.discount_percentage * ( 100 - new_discount ) ) / 100 ) );
			}
			
			return formatData.currency( new_discount == 0 ? prev_price * include_quantity : calcDiscountPrice( prev_price, new_discount, prev_quantity ) * include_quantity, new_discount == 0 ? null : false).format;
		},
		textPrice(){
			return ['collection', 'price-catalog'].includes(this.type) && this.discount_price.min_price != this.discount_price.max_price ? "From " : "";
		},
		textDiscountPrice(){
			return ['collection', 'price-catalog'].includes(this.type) && this.discount_price.min_price != this.discount_price.max_price ? "Now" : "";
		}
	}
}
