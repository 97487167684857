
import { mapGetters, mapActions } from 'vuex';
export default {
    props:["openSearchMobile"],
    data() {
        return {
            showResults	: null,
            openSearch	: this.openSearchMobile,
            searchText	: '',
            show_navbar : false
        }
    },
    async mounted(){
        
        this.$root.$on('open-search-mobile', this.isOpenSearch);
    },
    components: {
        searchResult	: () => import('../../components/header/search-result.vue')
    },
    computed: {
        ...mapGetters({
            getterSearchProduct		: 'getterSearchProduct'
        }),
        getSearchText(){
            return this.searchText;
        }
    },
    methods: {
        ...mapActions({
            searchProduct	: 'searchProduct',
        }),
        closeSearch() {
            this.showResults = null;
            this.searchText = '';
            this.openSearch = false;
        },
        showResultsSearch(dataResults){
            this.showResults = dataResults;
        },
        searchProductWidget(event) {
            
            this.$cookies.set('value-search', this.getSearchText, { path: '/', maxAge: 60 * 60 * 24 * 7 });

            if( event.keyCode == 13 ){
                if (process.client) {
                    this.closeSearch();
                    this.$root.$emit('execute-view-more');
                    // this.$router.replace({ path: `/collections/search`, query: { result: this.getSearchText } });
                }
            }
            else{
                
				const regex_search = new RegExp(`^(?=.*${ this.getSearchText })${ !this.getterLogin ? '(?!.*private-label)' : '' }.*`, 'i');
				this.$root.$emit('charge-search-result', { list: [...this.getterSearchProduct.filter( (item) => regex_search.test( item.search_field ) ).slice(0, 3)], search_text: this.getSearchText });
            }
        },
        isOpenSearch(dataOpen){
            
            this.closeSearch();
            this.openSearch = dataOpen;
        },
        selectSearchOption(){
            if (process.client) {
                this.closeSearch();
                this.$root.$emit('execute-view-more');
                // this.$router.replace({ path: `/collections/search`, query: { result: this.getSearchText } });
            }
        },
    }
}
