
export default {
    props:["type"],
    data() {
        return {
            back_url		: process.env.VUE_APP_HEFESTO_API_URL_FILE,
            isLoading		: true,
            searchItems		: [],
            searchResults	: false,
            openSearch		: false,
            search_text	    : ''
        }
    },
    async mounted(){

        this.$root.$on('charge-search-result', this.getSearchResult);
        this.$root.$on('execute-view-more', this.executeSearchCollection);
    },
    components: {
        vcLoading		: () => import('../../components/pages/loading.vue' ),
        vcFormatPrice	: () => import('../../components/product/format-price.vue' )
    },
    computed: {
        getSearchItems(){
            return this.searchItems;
        },
        getSearchText(){
            return this.search_text;
        },
        getValidSearchResult(){
            return this.searchResults;
        }
    },
    methods: {
        selectSearchOption(event, navigate){
            
            this.$root.$emit("close-search-result");
            navigate(event);
        },
        getSearchResult(search_result){
            if( search_result.search_text == "") {
                search_result.list = [];
            }
            this.searchResults  = search_result.list?.length > 0;
            this.searchItems    = search_result.list || [];
            this.search_text    = search_result.search_text;
            this.$root.$emit("show-results-search", this.searchResults);
        },
        executeSearchCollection(){
            
            this.$refs.searchViewMore.click();
        }
    }
}
